import { useState } from 'react';
import classNames from 'classnames';
import { IconManSilhouette, IconNonBinarySilhouette, IconWomanSilhouette } from '@medifind/icons';
import { colorLookup, isPrerender } from '@medifind/utils';
import { Skeleton } from '../utils';
import styles from './Headshot.module.scss';

const Silhouette = ({ gender }) => {
  if (gender === 'F') return <IconWomanSilhouette />;
  if (gender === 'M') return <IconManSilhouette />;
  return <IconNonBinarySilhouette />;
};
const Headshot = (props) => {
  const {
    scoreCalc,
    doctor,
    large,
    showScore,
    small,
    className,
    micro,
    classes = {},
    useV2Style,
    tierFullCircle,
    showScoreLabel = false,
  } = props;
  const { score: overallScore, image, demographics } = doctor || {};
  const score = !!scoreCalc ? scoreCalc : !!overallScore ? overallScore : null;
  // 4 tier system: (tier # being used for CSS)
  // --  0-24 = tier 1 "experienced"
  // -- 25-49 = tier 2 "advanced"
  // -- 50-74 = tier 3 "distinguished"
  // -- 75+   = tier 4 "elite"
  // how;
  // -- Math.min(x, 4) ensures maximum possible tier of 4
  // -- Math.floor(x + 1) creates first-inclusive/last-exclusive tiers (i.e. 0 <= x < 25)
  // -- x / 25 divides the score into 4 sub-sets (a percentile from 0-100; although nothing less than 5 will be given to the front-end)
  const tier = showScore && score !== 0 && score != null ? Math.min(Math.floor(score / 25) + 1, 4) : null;
  return (
    <div
      className={classNames(styles['headshot'], useV2Style ? styles['v2-card__headshot'] : styles['v1-card__headshot'], classes.headshot, {
        [styles['medium']]: !large && !small && !className,
        [styles['large']]: large,
        [styles['small']]: small,
        [styles['micro']]: micro,
        [styles['score-margin']]: showScore && tier,
      })}
    >
      <div className={classNames(styles['round-outline'])}>
        <div className={classNames(styles['hs-image'])}>
          {doctor ? (
            image ? (
              <DoctorImage
                image={doctor.image}
                alt={doctor.name ?? doctor.displayName ?? doctor.display}
                large={large}
                small={small || micro}
              />
            ) : (
              <Silhouette gender={demographics?.sex || doctor?.gender} />
            )
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
      <Score score={tier} hasProject={showScore} small={small || micro} fullCircle={tierFullCircle} showScoreLabel={showScoreLabel} />
    </div>
  );
};

const FullCircle = ({ color }) => {
  const colorCode = colorLookup(color) ?? colorLookup('black');
  return (
    <>
      {/* full circle desktop */}
      <svg
        width="185"
        height="185"
        viewBox="0 0 185 185"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(styles['tier-full-circle'], styles['hide-mobile'])}
      >
        <circle cx="92.5" cy="92.5" r="90" stroke={colorCode} strokeWidth="5" />
      </svg>
      {/* full circle mobile */}
      <svg
        width="185"
        height="185"
        viewBox="0 0 190 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(styles['tier-full-circle'], styles['hide-desktop'])}
      >
        <circle cx="95" cy="95" r="90" stroke={colorCode} strokeWidth="10" />
      </svg>
    </>
  );
};

const RadialCircle = ({ color, viewBox, path, width, height }) => {
  const colorCode = colorLookup(color) ?? colorLookup('black');
  return (
    <>
      {/* radial segmented circle desktop */}
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(styles['tier-radial-circle'], styles['hide-mobile'])}
      >
        <path d={path} stroke={colorCode} strokeWidth="4" strokeLinecap="round" />
      </svg>
      {/* full circle mobile (different from other full circle mobile -- for v1 cards/non-segmented circles) */}
      <svg
        width="76"
        height="76"
        viewBox="0 0 76 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames(styles['tier-full-circle'], styles['hide-desktop'])}
      >
        <circle cx="38" cy="38" r="35.5" stroke={colorCode} strokeWidth="4" />
      </svg>
    </>
  );
};

const colors = {
  // experienced
  1: 'tier1',
  // advanced
  2: 'tier2',
  // distinguished
  3: 'tier3',
  // elite
  4: 'tier4',
};

const radialPropsMap = {
  // experienced
  1: {
    width: '67',
    height: '67',
    viewBox: '0 0 67 67',
    path: 'M64.5 65C29.9822 65 2 37.0178 2 2.5',
  },
  // advanced
  2: {
    width: '67',
    height: '129',
    viewBox: '0 0 67 129',
    path: 'M64.5 127C29.9822 127 2 99.0178 2 64.5C2 29.9822 29.9822 2 64.5 2',
  },
  // distinguished
  3: {
    width: '129',
    height: '129',
    viewBox: '0 0 129 129',
    path: 'M127 64.5C127 29.9822 99.0178 2 64.5 2C29.9822 2 2 29.9822 2 64.5C2 99.0178 29.9822 127 64.5 127',
  },
  // elite
  4: {
    width: '129',
    height: '129',
    viewBox: '0 0 129 129',
    path: 'M67.5 126.969C101.092 125.913 127 98.3486 127 64.5C127 29.9822 99.0178 2 64.5 2C29.9822 2 2 29.9822 2 64.5C2 98.3486 28.9077 125.913 62.5 126.969',
  },
};

const Score = ({ score, hasProject, small, fullCircle, showScoreLabel = false }) => {
  if (!score || score < 0 || hasProject === false || small) return null;
  const scoreClass = classNames(styles['medifind-score'], styles[`tier-${score}`], { [styles[`tier-${score}--label`]]: showScoreLabel });
  const color = colors[score];
  const radialProps = radialPropsMap[score] ?? {};
  return <div className={scoreClass}>{fullCircle ? <FullCircle color={color} /> : <RadialCircle color={color} {...radialProps} />}</div>;
};
const DoctorCircleImage = ({ className, ...props }) => {
  return (
    <div className={classNames(className, styles['headshot'])}>
      <div className={classNames(styles['round-outline'])}>
        {props.image && (
          <div className={classNames(styles['hs-image'])}>
            <DoctorImage {...props} />
          </div>
        )}
      </div>
    </div>
  );
};
const DoctorImage = ({ image, alt, small }) => {
  const smallPngImg = `${image}-75x75.png`;
  const largePngImg = `${image}-350x350.png`;
  const largeWebpImg = `${image}-350x350.webp`;
  const [hideImage, setHideImage] = useState(false);

  return small ? (
    <img
      loading="lazy"
      src={smallPngImg}
      alt={alt}
      style={{ display: hideImage ? 'none' : 'initial' }}
      onError={(e) => {
        if (!isPrerender) setHideImage(true);
      }}
    />
  ) : (
    <picture>
      <source srcSet={largeWebpImg} />
      <img
        loading="lazy"
        src={largePngImg}
        alt={alt}
        style={{ display: hideImage ? 'none' : 'initial' }}
        onError={(e) => {
          if (!isPrerender) setHideImage(true);
        }}
      />
    </picture>
  );
};

export { Headshot as default, Headshot, DoctorCircleImage };
